import * as Environment from "../base/Environment.js";

import {RenderContext, Stage, AnimatableSprite} from "../base/Display2D.js";

import {SiteSection} from "./SiteSection.js";
export {SiteSection};

import {MouseTracker} from "../components/MouseTracker.js";

import {TourSection} from "./TourSection.js";
import {OverlayController} from "./OverlayController.js";
import {ChatController} from "./ChatController.js";

//
// Site extends Stage
//

export const Site = function (context) {
	Stage.apply (this, arguments);

	if (Site.sharedInstance)
		throw new Error ("Cannot instantiate. Site is singleton.");

	Site.sharedInstance = this;

	var element = this.element;
	element.classList.remove ("sprite");

	if (element.getAttribute ("data-disable-chat") == "yes" ||
		!element.querySelector (".chat-controller"))
		Site.ENABLE_CHAT = false;

	var sections = this.sections = new Array ();
	this.setUpContent ();

	if (Site.ENABLE_CHAT && Site.PRELOAD_CHAT)
		this.setUpChat ();

	context.resizeDispatcher.addListener ("scroll", this.scroll, this);

	this.addListener ("resize", this.resize, this);
	this.resize ();

	window.setTimeout (function () {
		this.resize ();

	}.bind (this), 50);

	if (Environment.FAST_PASS) {
		/*
		window.setTimeout (function () {
			 this.openChat ();
			// Site.openChat ("max-mustermann");

		}.bind (this), 2000);
		*/
		
		/*
		window.setTimeout (function () {
			Site.openCreditPoints (true);

		}.bind (this), 550);
		*/

		/*
		window.setTimeout (function () {
			this.enableWideChat (true);

		}.bind (this), 550);
		*/

		/*
		window.setTimeout (function () {
			// Site.openOverlay ("opinionaire");
			// Site.openOverlay ("help");
			 Site.openOverlay ("downloads");
			// Site.openOverlay ("agenda");
			// Site.openOverlay ("privacy");

		}.bind (this), 50);
		 */

	}

};

window.Site = Site;

Site.ENABLE_CHAT = false; // true && !Environment.IS_IE;
Site.PRELOAD_CHAT = true;

Site.openChat = function (channelName) {
	this.sharedInstance.openChat (channelName);

};

Site.openOverlay = function (overlayName) {
	this.sharedInstance.openOverlay (overlayName);

};

Site.prototype = Object.create (Stage.prototype);

Site.prototype.resizeContext = function (context) {
	if (this.skipResize)
		return;

	this.setSize (
		Environment.IS_TOUCH_DEVICE ?
			RenderContext.getViewportSize () :
			this.getRulerSize ()

	);

};

Site.prototype.setUpContent = function () {
	const mainElement = this.mainElement = document.querySelector ("main");
	const footerElement = this.footerElement = document.querySelector ("footer");

	var element = this.element;

	const mouseTracker = this.mouseTracker = this.attachSprite (MouseTracker);
	mouseTracker.takeElement (element);

	mouseTracker.accelerationFactor = .005; // .0085; // .005;
	mouseTracker.dampFactor = .9;

	this.setUpSectionControllers (element);

	this.setUpNavigation ();

};

Site.prototype.setUpSectionControllers = function (element) {
	var sectionElements = element.querySelectorAll ("[data-class-name]");
	if (!sectionElements.length)
		sectionElements = [element];

	var sections = this.sections;

	for (var i = 0; i < sectionElements.length; i++) {
		var sectionElement = sectionElements [i];
		if (sectionElement.getAttribute ("data-processed") == "yes")
			continue;

		var section = sectionElement.section;

		if (!section) {
			sectionElement.setAttribute ("data-processed", "yes");

			var sectionClassName = sectionElement.getAttribute ("data-class-name");

			section = sectionElement.section = this.attachSprite (window [sectionClassName] || SiteSection);
			section.takeElement (sectionElement);

			sections.push (section);

		}

	}

};

Site.prototype.setUpNavigation = function () {
	const element = this.element;
	const headerNavigation = this.headerNavigation = element.querySelector (".header-navigation");

	const chatButton = this.chatButton = headerNavigation.querySelector (".tools-button--chat");
	if (chatButton && !Site.ENABLE_CHAT)
		chatButton.parentNode.removeChild (chatButton);

	const calendarButton = this.calendarButton = headerNavigation.querySelector (".tools-button--calendar");

	const downloadButton = this.downloadButton = headerNavigation.querySelector (".tools-button--files");

	const helpButton = this.helpButton = headerNavigation.querySelector (".tools-button--help");
	const privacyButton = this.privacyButton = headerNavigation.querySelector (".tools-button--privacy");

};

Site.prototype.setShowsNotification = function (showsNotification) {
	const notificationBadge = this.chatButton.firstElementChild;
	notificationBadge.style.display = showsNotification ? "" : "none";

};

Site.prototype.resize = function (sender) {
	this.isResizing = true;

	const viewportSize = this.size;

	const mouseTracker = this.mouseTracker;
	mouseTracker.setViewSize (viewportSize);

	this.chatWidth = 365 + 12;

	this.updateSceenLayout ();

	if (sender)
		this.renderInContext ();

	this.scroll ();

	this.isResizing = false;

};

Site.prototype.updateSceenLayout = function () {
	const viewportSize = this.size.concat ();
	const sections = this.sections;

    const headerHeight = this.headerNavigation.offsetHeight;
	viewportSize [1] -= headerHeight;

	let shiftOut;

	const chatController = this.chatController;
	if (chatController && !chatController.element.style.visibility) {
		const chatWidth = this.chatWidth;

		const chatPercentage = this.chatPercentage;
		const wideChatExpansion = this.wideChatExpansion || 0;

		const missingWidth = viewportSize [0] - chatWidth + 4;

		const chatOffset = Math.round (
			chatWidth * chatPercentage +
			missingWidth * wideChatExpansion * chatPercentage

		);
		viewportSize [0] -= chatOffset;

		chatController.setPosition (
			viewportSize [0],
			headerHeight

		);
		chatController.setViewSize ([
			Math.max (chatWidth, chatOffset),
			viewportSize [1]

		]);

		shiftOut = Math.max (0, missingWidth * wideChatExpansion * chatPercentage);
		viewportSize [0] += shiftOut;

	}

	const overlayController = this.overlayController;

	if (overlayController && overlayController.isAwake) {
		overlayController.setPosition (-shiftOut, headerHeight);
		overlayController.setViewSize (viewportSize);

	}

	for (let i = sections.length; i--;) {
		const section = sections [i];

		section.setPosition (-shiftOut, headerHeight);
		section.setViewSize (viewportSize);

	}


};

Site.prototype.scroll = function (sender) {
	var size = this.size;
	var sections = this.sections;

	for (var i = sections.length; i--;) {
		var section = sections [i];
		var sectionElement = section.element;

		var pageBounds = section.pageBounds = sectionElement.getBoundingClientRect ();

		if (// pageBounds.height &&
			pageBounds.top < size [1] * 1.25 &&
			pageBounds.bottom > -size [1] * .5) {
			if (!section.didStartLoading) {
				section.startLoading ();

			}

		}

		if (// pageBounds.height &&
			pageBounds.top < size [1] + (section.viewOffsetTop || 0) &&
			pageBounds.bottom > 0) {
			if (!section.isAwake)
				section.awake ();

			section.scroll (!sender);

		} else {
			if (section.isAwake)
				section.sleep ();

			continue;

		}

	}

};

Site.prototype.openChat = function (channelDescription) {
	if (!Site.ENABLE_CHAT)
		return;

	const chatController = this.chatController;
	const chatElement = chatController.element;
	const expareaWindow = chatElement.querySelector ("iframe").contentWindow;
	if (!(expareaWindow.ChatController && expareaWindow.ChatController.sharedInstance))
		return;

	expareaWindow.ChatController.setShowsChatNavigation (!channelDescription);

	if (!channelDescription)
		channelDescription = {
			id: 1,
			label: "Allgemeiner Chat",
			type: "Wy\\LaravelChat\\Models\\Channel"

		};

	const channelName = channelDescription.id + "|" + channelDescription.type;

	const state = this.states ["ShowChat"] || {phase: 0};
	const delayed = state.phase < .1;

	const section = TourSectionController.sharedInstance.currentSection;

	if (channelName != "undefined|undefined") {
		if (chatController && chatController.channelName != channelName) {
			chatController.setChannelName (channelName, delayed);
			expareaWindow.ChatController.selectChat (channelDescription);

			if (this.showsChat) {
				if (section.name == "lounge")
					this.enableWideChat (true);

				return;

			}

		}

	}

	const showsChat = this.showsChat = !this.showsChat;

	if (channelName != "close") {

		if (section.name == "lounge") {
			if (showsChat) {
				this.enableWideChat (true);

			} else {
				if (!this.isWideChatEnabled) {
					this.showsChat = true;
					this.enableWideChat (true);
					return;

				}

			}

		} else {
			this.enableWideChat (false);

		}

	}

	const chatButton = this.chatButton;

	const rate = SiteSection.TRANSITION_SPEED * (this.isWideChatEnabled ? .75 : 1);

	if (showsChat) {
		this.setUpChat ();

		const element = this.element;

		if (chatElement.style.visibility)
			chatElement.style.visibility = "";

		this.chatController.setChannelName (channelName, delayed);

		chatButton.classList.add ("active");
		this.startAnimation ("ShowChat", {direction: 1, rate: rate});

		if (channelName)
			expareaWindow.ChatController.selectChat (channelDescription);

	} else {
		chatButton.classList.remove ("active");
		this.startAnimation ("ShowChat", {direction: 0, rate: rate});

	}

};

Site.prototype.setUpChat = function () {
	let chatController = this.chatController;
	if (chatController)
		return;

	const element = this.element;
	const chatElement = element.querySelector (".chat-controller");

	if (!chatElement)
		return;

	chatController = this.chatController = this.attachSprite (ChatController);
	chatController.takeElement (chatElement);

	chatElement.style.visibility = "hidden";
	this.element.appendChild (chatElement);

};

Site.prototype.animateShowChat = function () {
	const state = this.updatedState ("ShowChat");
	let t = state.phase;
	t = .5 - Math.cos (Math.PI * t) * .5;
	t = .5 - Math.cos (Math.PI * t) * .5;

	this.chatPercentage = t;

	this.updateSceenLayout ();

	this.renderInContext ();

};

Site.prototype.enableWideChat = function (isWideChatEnabled) {
	if (this.isWideChatEnabled == isWideChatEnabled)
		return;

	this.isWideChatEnabled = isWideChatEnabled;

	const chatPercentage = this.chatPercentage;

	if (chatPercentage) {
		const rate = SiteSection.TRANSITION_SPEED * .75;

		if (isWideChatEnabled)
			this.startAnimation ("WideChat", {direction: 1, rate: rate});
		else
			this.startAnimation ("WideChat", {direction: 0, rate: rate});

	} else {
		this.stopAnimation ("WideChat");

		const wideChatExpansion = this.wideChatExpansion = isWideChatEnabled ? 1 : 0;
		this.states ["WideChat"] = {phase: wideChatExpansion};

	}

	this.renderInContext ();

};

Site.prototype.animateWideChat = function () {
	const state = this.updatedState ("WideChat");

	let t = state.phase;
	t = .5 - Math.cos (Math.PI * t) * .5;
	t = .5 - Math.cos (Math.PI * t) * .5;

	this.wideChatExpansion = t;
	this.updateSceenLayout ();

};

Site.prototype.openOverlay = function (overlayName) {
	if (overlayName == this.overlayName) {
		this.closeOverlay ();
		return;

	}

	this.revertOverlayNavigationState ();

	this.overlayName = overlayName;

	const selector = ".site-assets ." + overlayName + "-overlay";

	let overlayController = this.overlayController;
	if (!overlayController)
		overlayController = this.overlayController = this.attachSprite (OverlayController);

	const element = this.element;
	const overlayContent = element.querySelector (selector);

	overlayController.takeContentElement (overlayContent);

	overlayController.awake ();
	overlayController.open ();

	this.updateSceenLayout ();

	this.renderInContext ();


	switch (overlayName) {
		case "agenda":
			this.calendarButton && this.calendarButton.classList.add ("active");
			break;
		case "downloads":
			this.downloadButton && this.downloadButton.classList.add ("active");
			break;
		case "help":
			this.helpButton && this.helpButton.classList.add ("active");
			break;
		case "privacy":
			this.privacyButton && this.privacyButton.classList.add ("active");
			break;

	}


};

Site.prototype.revertOverlayNavigationState = function () {
	const overlayName = this.overlayName;

	switch (overlayName) {
		case "agenda":
			this.calendarButton && this.calendarButton.classList.remove ("active");
			break;
		case "downloads":
			this.downloadButton && this.downloadButton.classList.remove ("active");
			break;
		case "help":
			this.helpButton && this.helpButton.classList.remove ("active");
			break;
		case "privacy":
			this.privacyButton && this.privacyButton.classList.remove ("active");
			break;

	}

};

Site.prototype.closeOverlay = function () {
	const overlayController = this.overlayController;
	overlayController.close ();

	this.revertOverlayNavigationState ();

	this.overlayName = undefined;

};

Site.openCreditPoints = function () {
	this.sharedInstance.openCreditPoints ();

};

Site.prototype.openCreditPoints = function () {
	const tourSectionController = TourSectionController.sharedInstance;

	const currentSectionName = tourSectionController.currentSection.name;

	if (currentSectionName == "opinionaire") {
		TourSection.toggleSplitView ('credits');

	} else {
		if (!tourSectionController.isTransitioning) {
			tourSectionController.transitionToSectionByName ("opinionaire");
			tourSectionController.afterTransitionCallback = function () {
				TourSection.toggleSplitView ('credits');

			}

		}

	}

};
