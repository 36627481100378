import * as Environment from "../base/Environment.js";
import {parseTuple} from "../base/Base.js";

import {RenderContext, AnimatableSprite, TexturedSprite} from "../base/Display2D.js";
import {VideoSprite} from "../base/Display2DExtensions.js";

import {Site} from "./Site.js";
import {SiteSection} from "./SiteSection.js";
import {ExpertTourSplitView, BonusTourSplitView} from "./TourSplitView.js";

//
// TourSectionController extends SiteSection
//

export const TourSectionController = function (context) {
	SiteSection.apply (this, arguments);

	if (TourSectionController.sharedInstance)
		throw new Error ("Cannot instantiate. TourSectionController is singleton.");

	TourSectionController.sharedInstance = this;

};

window.TourSectionController = TourSectionController;

TourSectionController.goBack = function () {
	const controller = TourSectionController.sharedInstance;

	if (controller.currentSection.name == "foyer")
		return;

	controller.transitionToSectionByName ("foyer");

};

TourSectionController.prototype = Object.create (SiteSection.prototype);

TourSectionController.prototype.takeElement = function (element) {
	SiteSection.prototype.takeElement.apply (this, arguments);

	element.style.visibility = "";

	this.setUpContent ();

};

TourSectionController.prototype.setUpContent = function () {
	const element = this.element;

	const sectionElements = element.querySelectorAll ("[data-class-name]");

	const sections = this.sections = new Array ();
	const sectionMap = this.sectionMap = new Object ();

	const context = this.context;

	for (var i = 0; i < sectionElements.length; i++) {
		const sectionElement = sectionElements [i];
		sectionElement.setAttribute ("data-processed", "yes");

		const sectionClassName = sectionElement.getAttribute ("data-class-name");
		const section = new (window [sectionClassName] || TourSection) (context);
		section.takeElement (sectionElement);

		sectionMap [i] = section;
		sectionMap [section.name] = section;
		sections.push (section);

	}

	const section = this.currentSection =
		sectionMap [Environment.FAST_PASS ? "auditorium" : "foyer"] ||
		sections [0];

	this.addChild (section);

	if (Environment.FAST_PASS) {
		/*
		window.setTimeout (function () {
			Site.openChat ();

		}.bind (this), 250);
		*/

		/*
		window.setTimeout (function () {
			TourSection.toggleSplitView('');
			// TourSection.toggleSplitView('credits');

		}.bind (this), 500);
		*/

		/*
		window.setTimeout (function () {
			this.transitionToSectionByName ("lounge");

		}.bind (this), 250);
		*/

		/*
		window.setTimeout (function () {
			section.toggleContentFullScreen ();

		}.bind (this), 1250);
		*/

	}

	const backButton = document.querySelector (".header-title");
	backButton.classList.add ("sprite--button");
	backButton.addEventListener ("click", TourSectionController.goBack);

};

TourSectionController.prototype.awake = function () {
	SiteSection.prototype.awake.apply (this, arguments);

	const section = this.currentSection;
	section.awake ();

};

TourSectionController.prototype.sleep = function () {
	SiteSection.prototype.sleep.apply (this, arguments);

	const section = this.currentSection;
	section.sleep ();

};

TourSectionController.prototype.setViewSize = function (viewSize) {
	SiteSection.prototype.setViewSize.apply (this, arguments);

	this.updateSectionLayout ();

};

TourSectionController.prototype.updateSectionLayout = function () {
	const viewSize = this.viewSize;
	const section = this.currentSection;

	section.setViewSize (viewSize);

};

TourSectionController.prototype.transitionToSectionByName = function (sectionName) {
	if (this.isTransitioning)
		return;

	const sections = this.sections;
	const sectionMap = this.sectionMap;

	const section = this.startSection = this.currentSection;
	const nextSection = this.nextSection = sectionMap [sectionName];

	if (!nextSection)
		return;

	this.afterTransitionCallback = undefined;

	/*
	const nextVideoPlayer = nextSection.videoPlayer;
	if (nextVideoPlayer && nextVideoPlayer.isStreamingPlayer) {
		// trace ("CATCH CLICK");

		if (nextSection.parent != this) {
			this.element.appendChild (nextSection.element);
			this.element.removeChild (section.element);

		}
		nextVideoPlayer.loadVideo ();

	}
	*/

	section.element.classList.add ("shape");
	nextSection.element.classList.remove ("shape");

	if (section.showsSplitView)
		section.toggleSplitView (undefined, .55);

	const transformOrigin =
		section.element.getAttribute ("data-transition-origin") ||
		nextSection.element.getAttribute ("data-transition-origin");

	section.contentContainer.firstElementChild.style.transformOrigin =
		nextSection.contentContainer.firstElementChild.style.transformOrigin = transformOrigin;

	this.isTransitioning = true;
	this.startAnimation ("Transition", {direction: 1, rate: .01 * .85, phase: 0});

	this.renderInContext ();

};

TourSectionController.prototype.animateTransition = function () {
	const state = this.updatedState ("Transition");
	let t = state.phase;

	if (t == 1)
		this.isTransitioning = false;

	const sections = this.sections;

	let section = this.startSection;
	let nextSection = this.nextSection;

	if (nextSection == sections [0]) {
		t = 1 - t;
		section = this.nextSection;
		nextSection = this.startSection;

	}

	t = .5 - Math.cos (Math.PI * t) * .5;
	t = .5 - Math.cos (Math.PI * t) * .5;

	const viewSize = this.viewSize;

	function unloadSection (section) {
		if (section.isAwake) {
			section.sleep ();

			const videoPlayer = section.videoPlayer;
			// if (videoPlayer && videoPlayer instanceof TourStreamingVideoPlayer) {
			if (videoPlayer && videoPlayer.isStreamingPlayer) {
				// videoPlayer.element.style.display = "none";
				// document.body.appendChild (videoPlayer.element);

				// section.element.style.visibility = "hidden";
				section.element.style.display = "none";

			} else {
				this.removeChild (section);

			}

		}

	}

	function makeSectionCurrent (section) {
		if (!section.isAwake) {
			this.currentSection = section;

			const videoPlayer = section.videoPlayer;
			// if (videoPlayer && videoPlayer instanceof TourStreamingVideoPlayer) {
			if (videoPlayer && videoPlayer.isStreamingPlayer) {
				// videoPlayer.element.style.display = "";
				// section.perspectiveContainer.element.appendChild (videoPlayer.element);

				// section.element.style.visibility = "";
				section.element.style.display = "";

			}

			if (section.parent != this)
				this.addChild (section);

			this.updateSectionLayout ();

			section.awake ();

		}

	}

	if (t < .5) {
		if (section != nextSection)
			unloadSection.call (this, nextSection);

		t = t * 2;
		const t_ = 1 - t * 1.125;

		const scale = Math.pow (1.225, t);

		section.contentContainer.firstElementChild.style.transform = "scale(" + scale + ")";
		section.contentContainer.firstElementChild.style.opacity = t_ * 1.5;

		if (section.backButton)
			section.backButton.style.opacity = t_ * 20 - 19;

		makeSectionCurrent.call (this, section);

	} else {
		if (section != nextSection)
			unloadSection.call (this, section);

		t = (t - .5) * 2;
		const t_ = 1 - t;

		const scale = Math.pow (1.125, t - 1);

		nextSection.contentContainer.firstElementChild.style.transform = "scale(" + scale + ")";
		nextSection.contentContainer.firstElementChild.style.opacity = t * 1.5;

		if (nextSection.backButton)
			nextSection.backButton.style.opacity = t * 25 - 24;

		makeSectionCurrent.call (this, nextSection);
		if (t > .25) {
			if (this.afterTransitionCallback) {
				this.afterTransitionCallback ();
				this.afterTransitionCallback = undefined;

			}

		}

	}

};

//
// TourSection extends SiteSection
//

export const TourSection = function (context) {
	SiteSection.apply (this, arguments);

};

window.TourSection = TourSection;

TourSection.toggleSplitView = function (id) {
	const controller = TourSectionController.sharedInstance;
	const section = controller.currentSection;

	section.toggleSplitView (id);

};

TourSection.prototype = Object.create (SiteSection.prototype);

TourSection.prototype.takeElement = function (element) {
	if (this.element.parentNode)
		this.element.parentNode.removeChild (this.element);
	else
		element.parentNode.removeChild (element);

	this.element = element;

	element.style.visibility = "hidden";
	element.classList.add ("unselectable");

	const contentContainer = this.contentContainer = element.querySelector (".tour-section-content");

	const name = this.name = element.id;
	this.coverBackground = element.getAttribute ("data-background-fill") == "cover";

	this.setUpBackgroundImage ();
	this.setUpVideoPlayer ();
	this.setUpHotSpots ();

	if (name != "foyer")
		this.setUpBackButton ();

};

TourSection.prototype.setUpBackgroundImage = function () {
	const element = this.element;

	const backgroundContainer = this.backgroundContainer = this.attachSprite ();
	backgroundContainer.renderSelfInContext = function () {};
	backgroundContainer.element.classList.add ("tour-background-image-container");

	const backgroundFillColour = element.getAttribute ("data-background-fill-colour");
	if (backgroundFillColour)
		backgroundContainer.element.style.backgroundColor = backgroundFillColour;

	const imagesWrapper = document.createElement ("div");
	backgroundContainer.element.appendChild (imagesWrapper);

	const backgroundImagePath = this.backgroundImagePath =
		/* Environment.CDN_BASE_PATH + "images/" + */
		element.getAttribute ("data-background-image");

	const backgroundImage = this.backgroundImage = backgroundContainer.attachSprite (TexturedSprite);
	backgroundImage.setAlpha (.1);
	imagesWrapper.appendChild (backgroundImage.element);

	backgroundImage.addListener ("complete", this.completeBackgroundImage, this);
	backgroundImage.loadTexture (backgroundImagePath);

	const contentContainer = this.contentContainer;
	contentContainer.firstElementChild.appendChild (backgroundContainer.element);

};

TourSection.prototype.completeBackgroundImage = function (backgroundImage) {
	const element = this.element;
	element.style.visibility = "";

	backgroundImage.fadeIn (2);

	const parent = this.parent;
	if (parent && parent.currentSection == this)
		parent.updateSectionLayout ();

	this.renderInContext ();

};

TourSection.prototype.setUpHotSpots = function () {
	const element = this.element;

	const hotSpotContainer = this.hotSpotContainer = element.querySelector (".tour-hot-spots");
	const hotSpots = this.hotSpots = new Array ();

	if (hotSpotContainer) {
		const hotSpotElements = hotSpotContainer.querySelectorAll (".tour-hot-spot");

		for (let i = hotSpotElements.length; i--;) {
			const hotSpotElement = hotSpotElements [i];

			const hotSpot = this.attachSprite (HotSpot);
			hotSpot.takeElement (hotSpotElement);

			hotSpot.addListener ("click", this.clickHotSpot, this);
			hotSpots.push (hotSpot);

			if (!Site.ENABLE_CHAT) {
				if (hotSpotElement.getAttribute ("href").indexOf ("openChat") >= 0) {
					this.removeChild (hotSpot);

				}

			}

		}

	}

};

TourSection.prototype.getPerspectiveContainer = function () {
	let perspectiveContainer = this.perspectiveContainer;
	if (!perspectiveContainer) {
		perspectiveContainer = this.perspectiveContainer = this.attachSprite ();
		perspectiveContainer.renderSelfInContext = function () {};
		perspectiveContainer.element.classList.add ("tour-perspective-container");

		const contentContainer = this.contentContainer;
		contentContainer.firstElementChild.appendChild (perspectiveContainer.element);

		const perpectiveCloseButton = this.perpectiveCloseButton = document.createElement ("a");
		perpectiveCloseButton.href = "#";
		perpectiveCloseButton.classList.add ("view-close-button");
		perpectiveCloseButton.style.display = "none";

		perpectiveCloseButton.addEventListener ("click", function (event) {
			event.preventDefault ();

			if (this.isContentFullScreen)
				this.toggleContentFullScreen ();

		}.bind (this));

		contentContainer.firstElementChild.appendChild (perpectiveCloseButton);

	}

	return perspectiveContainer;

};

TourSection.prototype.setUpVideoPlayer = function () {
	const element = this.element;

	const videoPlayerElement = this.videoPlayerElement = element.querySelector (".tour-video-player");
	if (!videoPlayerElement)
		return;


	const videoPlayerType = videoPlayerElement.getAttribute ("data-player-type") || "iframe";

	const videoSize = this.videoSize =
		parseTuple (videoPlayerElement.getAttribute ("data-player-aspect") || "16; 9");
	const videoPosition = this.videoPosition =
		parseTuple (videoPlayerElement.getAttribute ("data-player-position") || "1; -178");
	const videoScale = this.videoScale =
		parseFloat (videoPlayerElement.getAttribute ("data-player-scale") || "54.8");

	const videoPlayer = this.videoPlayer = this.attachSprite (
		videoPlayerType == "local" ?
			TourVideoPlayer :
			TourIFrameVideoPlayer

	);
	videoPlayer.takeElement (videoPlayerElement);

	const perspectiveContainer = this.getPerspectiveContainer ();
	const contentContainer = this.contentContainer;

	if (videoPlayer.isStreamingPlayer) {
		contentContainer.classList.add ("streaming-player");

		const zoomButton = videoPlayer.zoomButton;
		zoomButton.classList.add ("sprite--button");
		zoomButton.addEventListener ("click", this.clickVideoPlayer.bind (this));

	} else {
		contentContainer.classList.add ("streaming-player");

		const zoomButton = videoPlayer.zoomButton;
		zoomButton.classList.add ("sprite--button");
		zoomButton.addEventListener ("click", this.clickVideoPlayer.bind (this));

		videoPlayerElement.classList.add ("flipped");
		this.perpectiveCloseButton.classList.add ("flipped");

	}

	const languageButton = videoPlayer.languageButton;
	if (languageButton)
		languageButton.addEventListener ("click", this.toggleVideoLanguage.bind (this));

	perspectiveContainer.element.appendChild (videoPlayer.element);


	const messageLabel = this.element.querySelector (".tour-hot-spot--label");
	const messageButton = this.messageButton = document.createElement ("a");

	messageButton.href = messageLabel.parentNode.href;
	messageButton.classList.add ("tour-message-button");
	messageButton.classList.add ("action-button");
	messageButton.innerHTML = messageLabel.innerHTML

	messageButton.style.display = "none";

	if (!videoPlayer.isStreamingPlayer)
		messageButton.style.visibility = "hidden";

	messageButton.addEventListener ("mouseover", function () {
		this.isMouseOverMessageButton = true;

	}.bind (this));
	messageButton.addEventListener ("mouseout", function () {
		this.isMouseOverMessageButton = false;

	}.bind (this));

	contentContainer.firstElementChild.appendChild (messageButton);

};

TourSection.prototype.toggleVideoLanguage = function (event) {
	event.stopPropagation ();

	const language = TourVideoPlayer.LOCALE == "de" ?
		"en" : "de";

	TourVideoPlayer.LOCALE = language;
	this.videoPlayer.updateLanguage ();

};

TourSection.prototype.clickVideoPlayer = function (videoPlayer) {
	this.toggleContentFullScreen ();

};

TourSection.prototype.toggleContentFullScreen = function () {
	const isContentFullScreen = this.isContentFullScreen = !this.isContentFullScreen;

	const videoPlayer = this.videoPlayer;
	const backButton = this.backButton;
	const contentContainer = this.contentContainer;

	if (isContentFullScreen) {
		videoPlayer.element.classList.remove ("sprite--button");
		if (!videoPlayer.isStreamingPlayer)
			videoPlayer.removeListener ("click", this.clickVideoPlayer, this);

		backButton.classList.add ("shape");
		contentContainer.style.background = "#111111";

		this.startAnimation ("ZoomContent", {direction: 1, rate: SiteSection.TRANSITION_SPEED});

	} else {
		videoPlayer.element.classList.add ("sprite--button");
		if (!videoPlayer.isStreamingPlayer)
			videoPlayer.addListener ("click", this.clickVideoPlayer, this);

		backButton.classList.remove ("shape");
		contentContainer.style.background = "";

		this.startAnimation ("ZoomContent", {direction: 0, rate: SiteSection.TRANSITION_SPEED});

	}

};

TourSection.prototype.animateZoomContent = function () {
	const state = this.updatedState ("ZoomContent");

	let t = state.phase;
	t = .5 - Math.cos (Math.PI * t) * .5;
	t = .5 - Math.cos (Math.PI * t) * .5;

	this.perspectiveContentZoom = t;
	this.updatePerspectiveContentLayout (true);

};

TourSection.prototype.updatePerspectiveContentLayout = function (forceUpdate) {
	const perspectiveContainer = this.perspectiveContainer;
	if (!perspectiveContainer)
		return;

	const rotationVector = this.rotationVector;
	if (!rotationVector)
		return;

	const t = this.perspectiveContentZoom || 0;
	const lastPerspectiveContentZoom = this.lastPerspectiveContentZoom;
	this.lastPerspectiveContentZoom = t;

	if (!forceUpdate && t == 1 && lastPerspectiveContentZoom == 1)
		return;

	const t_ = 1 - t;

	const perspectiveContent = perspectiveContainer.element.firstElementChild;

	if (perspectiveContent)
		perspectiveContent.style.transform =
			"rotateX(" + rotationVector [0] * t_ + "rad) " +
			"rotateY(" + rotationVector [1] * t_ + "rad)";

	if (t == 0) {
		if (perspectiveContainer.element.style.zIndex)
			perspectiveContainer.element.style.zIndex = "";

	} else {
		if (!perspectiveContainer.element.style.zIndex)
			perspectiveContainer.element.style.zIndex = 1;

	}

	const videoPlayer = this.videoPlayer;
	const backgroundImageScale = this.backgroundImageScale;

	const videoSize = this.videoSize;
	const videoPosition = this.videoPosition;

	videoPlayer.video.setPosition (
		videoPosition [0] * backgroundImageScale * t_,
		videoPosition [1] * backgroundImageScale * t_

	);

	let videoScale = this.videoScale;

	const collapsedSize = [
		videoSize [0] * videoScale * backgroundImageScale,
		videoSize [1] * videoScale * backgroundImageScale

	];

	const viewSize = this.viewSize;

	const layoutSize = this.layoutSize || this.viewSize;

	videoScale = Math.min (
		layoutSize [0] / videoSize [0],
		layoutSize [1] / videoSize [1]

	);

	const expandedSize = [
		videoSize [0] * videoScale,
		videoSize [1] * videoScale

	];

	videoPlayer.video.setSize (
		collapsedSize [0] * t_ + expandedSize [0] * t,
		collapsedSize [1] * t_ + expandedSize [1] * t

	);

	videoPlayer.video.element.style.width = videoPlayer.video.size [0] + "px";
	videoPlayer.video.element.style.height = videoPlayer.video.size [1] + "px";

	videoPlayer.updateLayout ();


	const perpectiveCloseButton = this.perpectiveCloseButton;
	perpectiveCloseButton.style.opacity = t * 10 - 9;
	perpectiveCloseButton.style.display = t ? "" : "none";

	this.updateMessageButtonOpacityForT (t);

	const backButton = this.backButton;
	backButton.style.opacity = t_ * 10 - 9;
	backButton.style.display = t_ ? "" : "none";

	const zoomButton = videoPlayer.zoomButton;
	zoomButton.style.opacity = t_ * 10 - 9;
	zoomButton.style.display = t_ ? "" : "none";

	const backgroundContainerElement = this.backgroundContainer.element;
	if (t == 1) {
		if (!this.isTrackingMouseInFullscreen)
			this.startTrackMouseInFullscreen ();

		if (!videoPlayer.element.classList.contains ("fullscreen"))
			videoPlayer.element.classList.add ("fullscreen");

		if (!backgroundContainerElement.style.display)
			backgroundContainerElement.style.display = "none";

	} else {
		if (this.isTrackingMouseInFullscreen)
			this.stopTrackMouseInFullscreen ();

		if (videoPlayer.element.classList.contains ("fullscreen"))
			videoPlayer.element.classList.remove ("fullscreen");

		if (backgroundContainerElement.style.display)
			backgroundContainerElement.style.display = "";

		if (videoSize [0] / videoSize [1] != 16 / 9)
			backgroundContainerElement.style.opacity = 1 - t;

	}

	if (t == 0) {
		if (videoPlayer.element.classList.contains ("zoomed"))
			videoPlayer.element.classList.remove ("zoomed");

	} else {
		if (!videoPlayer.element.classList.contains ("zoomed"))
			videoPlayer.element.classList.add ("zoomed");

	}

};

TourSection.prototype.updateMessageButtonOpacityForT = function (t) {
	const messageButton = this.messageButton;
	messageButton.style.opacity = (t * 10 - 9) * (this.messageButtonOpacity || 0);
	messageButton.style.display = t ? "" : "none";

};

TourSection.prototype.startTrackMouseInFullscreen = function () {
	this.activityDelay = 120;

	if (this.isTrackingMouseInFullscreen)
		return;
	this.isTrackingMouseInFullscreen = true;

	this.markActivityInFullscreen ();

	if (!this._markActivityInFullscreen)
		this._markActivityInFullscreen = this.markActivityInFullscreen.bind (this);

	const videoPlayer = this;//.videoPlayer;
	videoPlayer.element.addEventListener ("mousemove", this._markActivityInFullscreen, true);

};

TourSection.prototype.stopTrackMouseInFullscreen = function () {
	if (!this.isTrackingMouseInFullscreen)
		return;
	this.isTrackingMouseInFullscreen = false;

	const videoPlayer = this;//.videoPlayer;
	videoPlayer.element.removeEventListener ("mousemove", this._markActivityInFullscreen, true);

};

TourSection.prototype.markActivityInFullscreen = function () {
	this.startAnimation ("FadeMessageButton", {direction: 1, rate: .08});

	this.activityDelay = 120;
	this.addRunLoopHandler ("processActivityInFullscreen");

};

TourSection.prototype.processActivityInFullscreen = function () {
	if (this.isMouseOverMessageButton)
		return;

	const activityDelay = this.activityDelay =
		Math.max (0, this.activityDelay - this.context.animationTimer.framesDelta);

	if (activityDelay)
		return;

	this.startAnimation ("FadeMessageButton", {direction: 0, rate: .08});
	this.removeRunLoopHandler ("processActivityInFullscreen");

};

TourSection.prototype.animateFadeMessageButton = function () {
	const state = this.updatedState ("FadeMessageButton");
	let t = state.phase;

	this.messageButtonOpacity = t;
	this.updateMessageButtonOpacityForT (this.perspectiveContentZoom || 0);

};

TourSection.prototype.setUpBackButton = function () {
	const element = this.element;

	let backButton = this.getStage ().element.querySelector (".site-assets .section-back-button");

	if (backButton) {
		backButton = this.backButton = backButton.cloneNode (true);
		this.contentContainer.appendChild (backButton);

	}

};

TourSection.prototype.awake = function () {
	SiteSection.prototype.awake.apply (this, arguments);

	this.skipBackgroundAnimation = false;
	this.addRunLoopHandler ("processBackgroundAnimation");

	const videoPlayer = this.videoPlayer;
	if (videoPlayer)
		videoPlayer.awake ();

	// this.getStage ().enableWideChat (this.name == "lounge");

};

TourSection.prototype.sleep = function () {
	SiteSection.prototype.sleep.apply (this, arguments);

	this.removeRunLoopHandler ("processBackgroundAnimation");

	const videoPlayer = this.videoPlayer;
	if (videoPlayer)
		videoPlayer.sleep ();

};

TourSection.prototype.processBackgroundAnimation = function (forceUpdate) {
	if (!this.isAwake)
		return;

	if (this.skipBackgroundAnimation) {
		this.skipBackgroundAnimation = false;
		return;

	}

	/*
	if (Environment.FAST_PASS && Environment.IS_IE)
		return;
	*/

	const mouseTracker = this.getStage ().mouseTracker;
	if (!forceUpdate && !mouseTracker.didUpdateMouse)
		return;

	const displacementVector = [0, 0];

	const relativePosition = mouseTracker.relativePosition.concat ();

	displacementVector [0] = relativePosition [0];
	displacementVector [1] = relativePosition [1];

	const rotationVector = this.rotationVector = [
		displacementVector [1] * 1.125 * -.005,
		displacementVector [0] * 1.25 * .005

	];

	if (this.coverBackground) {
		rotationVector [0] *= .75; // y
		rotationVector [1] *= .75; // x

	} else {
		rotationVector [0] *= 1.5; // y
		rotationVector [1] *= 1.5; // x

	}

	const backgroundContainer = this.backgroundContainer;
	backgroundContainer.element.firstElementChild.style.transform =
		"rotateX(" + rotationVector [0] + "rad) " +
		"rotateY(" + rotationVector [1] + "rad)";

	this.updatePerspectiveContentLayout (forceUpdate);


	const hotSpots = this.hotSpots;
	const scale = this.backgroundImageScale;

	if (scale)
		mouseTracker.didUpdateMouse = false;

	const perspective = 150;

	for (let i = hotSpots.length; i--;) {
		const hotSpot = hotSpots [i];

		const position = hotSpot.spacePosition.concat ();

		position [0] *= scale;
		position [1] *= scale;

		const yz = [
			position [1] * Math.cos (rotationVector [0]) - position [2] * Math.sin (rotationVector [0]),
			position [2] * Math.cos (rotationVector [0]) + position [1] * Math.sin (rotationVector [0])

		];
		position [1] = yz [0];
		position [2] = yz [1];


		const xz = [
			position [0] * Math.cos (-rotationVector [1]) - position [2] * Math.sin (-rotationVector [1]),
			position [2] * Math.cos (-rotationVector [1]) + position [0] * Math.sin (-rotationVector [1])

		];
		position [0] = xz [0];
		position [2] = xz [1];


		const z = position [2];
		const zFactor = perspective / (
			perspective - z

		);

		const hotSpotPosition = [
			position [0] * zFactor,
			position [1] * zFactor

		];

		if (!scale) {
			hotSpotPosition [0] = 0;
			hotSpotPosition [1] = -8192;

		}

		if (Environment.IS_IE) {
			hotSpotPosition [0] = Math.round (hotSpotPosition [0]);
			hotSpotPosition [1] = Math.round (hotSpotPosition [1]);

		}

		hotSpot.element.style.transform = "translate(" +
			hotSpotPosition [0] + "px, " +
			hotSpotPosition [1] + "px)";

		const areaElement = hotSpot.areaElement;
		const areaSize = hotSpot.areaSize;

		areaElement.style.width = Math.round (areaSize [0] * scale) + "px";
		areaElement.style.height = Math.round (areaSize [1] * scale) + "px";

		const labelElement = hotSpot.labelElement;
		const labelOffset = hotSpot.labelOffset;

		areaElement.style.left = Math.round (-labelOffset [0] * scale) + "px";
		areaElement.style.top = Math.round (-labelOffset [1] * scale) + "px";

	}

};

TourSection.prototype.setViewSize = function (viewSize) {
	this.viewSize = viewSize;
	const element = this.element;

	element.style.width = viewSize [0] + "px";
	element.style.height = viewSize [1] + "px";

	this.splitViewHeight = 250;

	this.updateSceenLayout ();

	this.processBackgroundAnimation (true);
	this.skipBackgroundAnimation = true;

};

TourSection.prototype.updateSceenLayout = function () {
	const viewSize = this.viewSize.concat ();

	const splitView = this.splitView;
	if (splitView) {
		viewSize [1] -= this.splitViewHeight * this.splitViewPercentage;

		splitView.setPosition (0, viewSize [1]);
		splitView.setViewSize ([
			viewSize [0],
			this.splitViewHeight

		]);

	}

	const backgroundImage = this.backgroundImage;
	const imageSize = backgroundImage.textureSize;

	const coverBackground = this.coverBackground;

	if (imageSize) {
		const scale = coverBackground ?
			Math.max (
				viewSize [0] / imageSize [0],
				viewSize [1] / imageSize [1]

			) :
			Math.min (
				viewSize [0] / imageSize [0],
				viewSize [1] / imageSize [1]

			);

		const overscale = 1.0667;
		const backgroundImageScale = this.backgroundImageScale = scale * overscale;

		backgroundImage.setSize (
			imageSize [0] * backgroundImageScale,
			imageSize [1] * backgroundImageScale

		);

		const layoutSize = this.layoutSize = [
			imageSize [0] * scale,
			imageSize [1] * scale

		];


		const contentContainer = this.contentContainer;
		contentContainer.style.left = (
			coverBackground ?
				(viewSize [0] - layoutSize [0]) * .125 * .5 :
				(viewSize [0] - layoutSize [0]) * .5

		) + "px";
		contentContainer.style.width = layoutSize [0] + "px";
		contentContainer.style.top = (
			coverBackground ?
				(viewSize [1] - layoutSize [1]) * .95 :
				(viewSize [1] - layoutSize [1]) * .5

		) + "px";

		contentContainer.style.height = layoutSize [1]  + "px";


		this.updatePerspectiveContentLayout (true);


	} else {
		this.viewSize = [0, 0];

	}

};

TourSection.prototype.clickHotSpot = function (hotSpot) {
	const event = hotSpot.currentEvent;
	event.preventDefault ();

	const name = hotSpot.name;
	if (name) {
		this.parent.transitionToSectionByName (name);

	} else {
		const href = hotSpot.element.getAttribute ("href");
		const target = hotSpot.element.getAttribute ("target");

		window.open (href, target || "_self");

	}

};

TourSection.prototype.toggleSplitView = function (id, rate) {
	const showsSplitView = this.showsSplitView = !this.showsSplitView;

	rate = SiteSection.TRANSITION_SPEED * (rate || 1);

	let splitView;
	if (showsSplitView) {
		const constructorFunction = id == "credits" ?
			BonusTourSplitView :
			ExpertTourSplitView;

		this.setUpSplitView (constructorFunction);
		splitView = this.splitView;

		if (!splitView)
			return;

		splitView.id = id;
		splitView.awake ();
		this.startAnimation ("ShowSplitView", {direction: 1, rate: rate});

	} else {
		splitView = this.splitView;

		splitView.sleep ();
		this.startAnimation ("ShowSplitView", {direction: 0, rate: rate});

	}

	if (splitView.id == "credits") {
		const creditsButton = Site.sharedInstance.headerNavigation.querySelector (".tools-button--credit-points");
		if (showsSplitView)
			creditsButton.classList.add ("active");
		else
			creditsButton.classList.remove ("active");

	}

};

TourSection.prototype.setUpSplitView = function (constructorFunction) {
	let splitView = this.splitView;
	if (splitView)
		return;

	const element = this.element;
	const splitViewElement = this.getStage ().element.querySelector (
		constructorFunction.formSelector || ".tour-split-view"

	);

	if (splitViewElement) {
		splitView = this.splitView = this.attachSprite (constructorFunction);
		splitView.takeElement (splitViewElement);

		element.appendChild (splitView.element);

	}

};

TourSection.prototype.animateShowSplitView = function () {
	const state = this.updatedState ("ShowSplitView");
	let t = state.phase;
	t = .5 - Math.cos (Math.PI * t) * .5;
	t = .5 - Math.cos (Math.PI * t) * .5;

	this.splitViewPercentage = t;

	this.updateSceenLayout ();

	this.renderInContext ();

};

//
// HotSpot extends AnimatableSprite
//

export const HotSpot = function (context) {
	AnimatableSprite.apply (this, arguments);

};

HotSpot.prototype = Object.create (AnimatableSprite.prototype);

HotSpot.prototype.takeElement = function (element) {
	this.element.parentNode.removeChild (this.element);
	this.element = element;

	const spacePosition = this.spacePosition = parseTuple (element.getAttribute ("data-position"));

	const areaElement = this.areaElement = element.querySelector (".tour-hot-spot--area");
	const areaSize = this.areaSize = parseTuple (areaElement.getAttribute ("data-size"));

	const labelElement = this.labelElement = element.querySelector (".tour-hot-spot--label");
	const labelOffset = this.labelOffset = parseTuple (labelElement.getAttribute ("data-offset"));

	if (!Site.ENABLE_CHAT) {
		const ieHref = element.getAttribute ("data-ie-href");
		if (ieHref)
			element.setAttribute ("href", ieHref);

	}

	const href = element.getAttribute ("href");
	if (href.charAt (0) == "#")
		this.name = href.substr (1);

};

//
// TourVideoPlayer extends AnimatableSprite
//

export const TourVideoPlayer = function (context) {
	AnimatableSprite.apply (this, arguments);

};

TourVideoPlayer.LOCALE = window.exparea_config ?
	exparea_config.locale : "de";

TourVideoPlayer.prototype = Object.create (AnimatableSprite.prototype);

TourVideoPlayer.prototype.takeElement = function (element) {
	this.element.parentNode.removeChild (this.element);
	this.element = element;

	const video = this.video = this.attachSprite (VideoSprite);
	video.playsInline = true;
	video.playsLooped = true;
	video.showsContols = true;

	this.createButtons ();

	this.getStage ().addListener ("click", this.catchClick, this);

};

TourVideoPlayer.prototype.catchClick = function (stage) {
	stage.removeListener ("click", this.catchClick, this);

	const video = this.video;
	video.forceUnmute = true;

	const videoElement = video.texture;

	videoElement.load ("");
	videoElement.volume = 1;
	videoElement.removeAttribute ("muted");
	videoElement.muted = false;

};

TourVideoPlayer.prototype.createButtons = function () {
	const video = this.video;
	const videoElement = video.element;

	const zoomButton = this.zoomButton = document.createElement ("div");
	zoomButton.classList.add ("video-player-zoom-button");

	videoElement.appendChild (zoomButton);

	const languageButton = this.languageButton = document.createElement ("div");
	languageButton.classList.add ("video-player-language-button");

	videoElement.appendChild (languageButton);

	this.updateLanguage ();

};

TourVideoPlayer.prototype.updateLanguage = function () {
	const languageButton = this.languageButton;
	languageButton.innerHTML = TourVideoPlayer.LOCALE == "de" ?
		"Original Audio" : "Translated Audio";

};

TourVideoPlayer.prototype.awake = function () {
	if (this.isAwake)
		return;

	this.isAwake = true;

	const video = this.video;

	if (this.didLoadVideo) {
		video.resumeVideo ();

	} else {
		this.loadVideo ();

	}

};

TourVideoPlayer.prototype.loadVideo = function () {
	this.didLoadVideo = true;

	const element = this.element;
	const videoName = element.getAttribute ("data-video-file");

	const video = this.video;
	video.loadVideo (
		/* Environment.CDN_BASE_PATH + "videos/" + */
		videoName

	);

};

TourVideoPlayer.prototype.sleep = function () {
	if (!this.isAwake)
		return;

	this.isAwake = false;

	const video = this.video;
	video.pauseVideo ();

};

TourVideoPlayer.prototype.updateLayout = function () {

};

//
//	TourStreamingVideoPlayer extends TourVideoPlayer
//

export const TourStreamingVideoPlayer = function (context) {
	TourVideoPlayer.apply (this, arguments);

};

TourStreamingVideoPlayer.ID = 0;

TourStreamingVideoPlayer.prototype = Object.create (TourVideoPlayer.prototype);

TourStreamingVideoPlayer.prototype.isStreamingPlayer = true;

TourStreamingVideoPlayer.prototype.takeElement = function (element) {
	this.element.parentNode.removeChild (this.element);
	this.element = element;

	const video = this.video = this.attachSprite ();
	video.element.classList.add ("streaming-player-container");

	this.createButtons ();

	const streamingPlayerElement = this.streamingPlayerElement = document.createElement ("div");
	const playerID = "streaming_player_" + ++TourStreamingVideoPlayer.ID;

	streamingPlayerElement.id = playerID;

	video.element.appendChild (streamingPlayerElement);

	const mouseTracker = this.getStage ().mouseTracker;

	video.addListener ("mouseover", function (sender) {
		if (this.parent.isContentFullScreen)
			return;

		mouseTracker.endTracking (sender);

	}, this);
	video.addListener ("mousemove", function (sender) {
		sender.currentEvent.stopPropagation ();

	}, this);
	video.addListener ("mouseout", function (sender) {
		/*
		const target = sender.currentEvent.target;
		while (target) {
			if (target == element)
				return;
			target = target.parentNode;

		}
		*/
		if (this.parent.isContentFullScreen)
			return;

		mouseTracker.beginTracking (sender);

	}, this);

};

TourStreamingVideoPlayer.prototype.awake = function (forceAwake) {
	if (this.isAwake && !forceAwake)
		return;

	this.isAwake = true;

	if (!window.js3q) {
		// trace ("player api not available. trying to resume.");

		window.setTimeout (function () {
			if (this.isAwake)
				this.awake (true);

		}.bind (this), 250);

		return;

	}

	const streamingPlayer = this.streamingPlayer;

	try {
		if (this.didLoadVideo) {
			streamingPlayer.play ();

		} else {
			this.loadVideo ();

		}

	} catch (exception) {

	}

	// this.streamingPlayer.resize ();
	// this.streamingPlayer.play ();

};

TourStreamingVideoPlayer.prototype.loadVideo = function () {
	this.didLoadVideo = true;

	this.createNewStreamingPlayer ();

};

TourStreamingVideoPlayer.prototype.updateLanguage = function () {
	TourVideoPlayer.prototype.updateLanguage.apply (this, arguments);

	this.createNewStreamingPlayer ();

};

TourStreamingVideoPlayer.prototype.createNewStreamingPlayer = function () {
	const streamingPlayerElement = this.streamingPlayerElement;
	if (!streamingPlayerElement)
		return;

	let streamingPlayer = this.streamingPlayer;
	try {
		if (streamingPlayer && typeof streamingPlayer.destroy == "function") {
			streamingPlayer.pause ();
			streamingPlayer.destroy ();

		}

	} catch (exception) {

	}

	const playerID = streamingPlayerElement.id;

	streamingPlayerElement.innerHTML = "";

	const locale = TourVideoPlayer.LOCALE;
	const language = locale == "de" ? "ger" : "eng";

	streamingPlayer = this.streamingPlayer = new js3q ({
		container: playerID,

		dataid: locale == "de" ?
			"3f498510-21f1-11eb-9d65-002590c750be" :
			"e807435f-21f1-11eb-9d65-002590c750be",
		// key: .26882707540551687,

		timestamp: 0,
		autoplay: true,
		allowmutedautoplay: true,

		// layout: "responsive",
		tintColor: "#282828",
		controlBarAutoHide: true,

		locale: locale,
		defaultCC: language,
		defaultAudioLanguage: language

	});

};

TourStreamingVideoPlayer.prototype.sleep = function () {
	if (!this.isAwake)
		return;

	this.isAwake = false;

	const streamingPlayer = this.streamingPlayer;

	try {
		if (streamingPlayer)
			streamingPlayer.pause ();

	} catch (exception) {

	}

};

TourStreamingVideoPlayer.prototype.updateLayout = function () {
	const streamingPlayer = this.streamingPlayer;
	if (!streamingPlayer)
		return;

	const video = this.video;
	const videoSize = video.size;

	if (video.size [0] == this.size [0] &&
		video.size [1] == this.size [1])
		return;

	this.size = videoSize.concat ();

	const stage = this.getStage ();

	if (!stage.isResizing) {
		stage.skipResize = true;

		if (typeof (Event) === "function") {
			// modern browsers

			window.dispatchEvent (new Event ("resize"));

		} else {
			// for IE and other old browsers
			// causes deprecation warning on modern browsers

			const event = window.document.createEvent ("UIEvents");
			event.initUIEvent ("resize", true, false, window, 0);
			window.dispatchEvent (event);

		}

		stage.skipResize = false;

	}

	streamingPlayer.resize ();

};

//
//	TourIFrameVideoPlayer extends TourVideoPlayer
//

export const TourIFrameVideoPlayer = function (context) {
	TourVideoPlayer.apply (this, arguments);

};

TourIFrameVideoPlayer.prototype = Object.create (TourVideoPlayer.prototype);

TourIFrameVideoPlayer.UNLOAD_I_FRAME_ON_SLEEP = true;

TourIFrameVideoPlayer.ID = 0;

TourIFrameVideoPlayer.prototype.isStreamingPlayer = true;

TourIFrameVideoPlayer.prototype.takeElement = function (element) {
	this.element.parentNode.removeChild (this.element);
	this.element = element;

	const video = this.video = this.attachSprite ();
	video.element.classList.add ("streaming-player-container");

	this.createButtons ();

	const streamingPlayerFrame = this.streamingPlayerFrame = document.createElement ("iframe");

	streamingPlayerFrame.setAttribute ("scrolling", "no");
	streamingPlayerFrame.id = "streaming_player_iframe_"  + ++TourIFrameVideoPlayer.ID;
	streamingPlayerFrame.classList.add ("video-player-i-frame");

	video.element.appendChild (streamingPlayerFrame);

	const site = Site.sharedInstance;
	const mouseTracker = site.mouseTracker;

	video.addListener ("mouseover", function (sender) {
		if (this.parent.isContentFullScreen)
			return;

		mouseTracker.endTracking (sender);

	}, this);
	video.addListener ("mousemove", function (sender) {
		sender.currentEvent.stopPropagation ();

	}, this);
	video.addListener ("mouseout", function (sender) {
		if (this.parent.isContentFullScreen)
			return;

		mouseTracker.beginTracking (sender);

	}, this);

};

TourIFrameVideoPlayer.prototype.awake = function (forceAwake) {
	if (this.isAwake && !forceAwake)
		return;

	this.isAwake = true;

	const streamingPlayerFrame = this.streamingPlayerFrame;

	if (this.didLoadVideo) {
		if (!TourIFrameVideoPlayer.UNLOAD_I_FRAME_ON_SLEEP) {
			if (!this.embedExternal) {
				const streamingPlayer = streamingPlayerFrame.contentWindow &&
					streamingPlayerFrame.contentWindow.streamingPlayer;

				if (streamingPlayer) {
					try {
						streamingPlayer.play ();

					} catch (exception) {
						console.log (exception);

					}

				} else {
					window.setTimeout (function () {
						if (this.isAwake)
							this.awake (true);

					}.bind (this), 250);

					return;

				}

			}

		}

	} else {
		this.loadVideo ();

	}

};

TourIFrameVideoPlayer.prototype.loadVideo = function () {
	this.didLoadVideo = true;

	this.loadIFrame ();

};

TourIFrameVideoPlayer.prototype.updateLanguage = function () {
	TourVideoPlayer.prototype.updateLanguage.apply (this, arguments);

	this.loadIFrame ();

};


TourIFrameVideoPlayer.prototype.loadIFrame = function () {
	const streamingPlayerFrame = this.streamingPlayerFrame;
	if (!streamingPlayerFrame)
		return;

	streamingPlayerFrame.setAttribute ("allowfullscreen", "");

	const element = this.element;
	const locale = TourVideoPlayer.LOCALE;
	
	const iFrameSrc =
		element.getAttribute ("data-iframe-src-" + locale) ||
		element.getAttribute ("data-iframe-src");
	const targetSrc =
		iFrameSrc ||
		"/index-video-content.html?locale=" + locale;
	
	this.embedExternal = !!iFrameSrc;
	
	if (!streamingPlayerFrame.src || !(Environment.IS_WEBKIT && !Environment.IS_SAFARI)) {
		streamingPlayerFrame.src = targetSrc;

	} else {
		streamingPlayerFrame.src = "";

		window.setTimeout (function () {
			streamingPlayerFrame.src = targetSrc;

		}, 16);

	}

};

TourIFrameVideoPlayer.prototype.sleep = function () {
	if (!this.isAwake)
		return;

	this.isAwake = false;

	const streamingPlayerFrame = this.streamingPlayerFrame;

	if (TourIFrameVideoPlayer.UNLOAD_I_FRAME_ON_SLEEP) {
		this.didLoadVideo = false;

		streamingPlayerFrame.src = "";

	} else {
		const streamingPlayer = streamingPlayerFrame.contentWindow &&
			streamingPlayerFrame.contentWindow.streamingPlayer;

		if (streamingPlayer) {
			try {
				streamingPlayer.pause ();

			} catch (exception) {
				console.log (exception);

			}

		}

	}

};

TourIFrameVideoPlayer.prototype.updateLayout = function () {
	const video = this.video;
	const videoSize = video.size;

	if (video.size [0] == this.size [0] &&
		video.size [1] == this.size [1])
		return;

	this.size = videoSize.concat ();

	const streamingPlayerFrame = this.streamingPlayerFrame;
	const contentWindow = streamingPlayerFrame.contentWindow;

	if (false && TourIFrameVideoPlayer.UNLOAD_I_FRAME_ON_SLEEP) {
		/*
		if (contentWindow) {
			if (typeof (Event) === "function") {
				// modern browsers

				contentWindow.dispatchEvent (new Event ("resize"));

			} else {
				// for IE and other old browsers
				// causes deprecation warning on modern browsers

				const event = contentWindow.document.createEvent ("UIEvents");
				event.initUIEvent ("resize", true, false, window, 0);
				contentWindow.dispatchEvent (event);

			}

		}
		*/

	} else {
		try {
			const streamingPlayer =	!this.embedExternal &&
				contentWindow && contentWindow.streamingPlayer;
			if (streamingPlayer)
				streamingPlayer.resize ();

		} catch (exception) {
			console.log (exception);

		}

	}

};
